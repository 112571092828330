import React, { useState } from 'react';

import { useRouter } from 'next/router';

import { Spin } from 'antd';

import { getTariffs } from '@/api/tariff/get-tariffs';
import useAsyncEffect from '@/hooks/use-async-effect';
import useMobile from '@/hooks/use-mobile';
import { Tariff } from '@/types/tariff';

import { TariffsTableDesktop } from './TariffsTableDesktop';
import { TariffsTableMobile } from './TariffsTableMobile';

import styles from './TariffsTable.module.scss';

export type TariffsTableProps = {
	/** Событие при выборе тарифа (клике на кнопку Приобрести или сменить) */
	onTariffChoice?: (params: {
		tariffInfo: Tariff.TariffWithSettings;
		perYear?: boolean;
		per_three_month?: boolean;
	}) => void;
	/** Выбор тарифа заблокирован */
	tariffChoiceDisable?: boolean;
	/** Если таблица больше этой высоты, невлезшая часть скрывается под спойлером */
	maxHeight?: number | string;
	/** Если пользователь авторизован, кнопки ведут на смену тарифа. Если нет - на покупку */
	userIsAuth?: boolean;
	/** ID текущего тарифа пользователя */
	currentTariff?: number;
	/** Текущий тариф пользователя активирован на год? */
	currentPerYear?: boolean;
	/** ID тарифа, который нужно выделить как "лучший выбор" */
	bestChoice?: number;
	/** Показывать кнопки? */
	showButtons?: boolean;
	/** Тариф активен? */
	isActiveTariff?: boolean;
	/** Данные о тарифах */
	tariffsData?: Tariff.TariffsInfoResponse;
};

export default function TariffsTable(props: TariffsTableProps) {
	const router = useRouter();
	const isMobile = useMobile();
	const [tariffsInfoData, setTariffsInfoData] = useState<Tariff.TariffsInfoResponse>(
		props.tariffsData,
	);
	const [isLoadingTariffs, setIsLoadingTariffs] = useState(true);

	// Получает тарифы
	useAsyncEffect(async () => {
		try {
			if (!props.tariffsData) {
				setIsLoadingTariffs(true);
				setTariffsInfoData(await getTariffs({ lang: router.locale }));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingTariffs(false);
		}
	}, []);

	if (isLoadingTariffs) {
		return (
			<div className={styles.spiner}>
				<Spin />
			</div>
		);
	}

	return isMobile ? (
		<TariffsTableMobile {...props} tariffsInfoData={tariffsInfoData} />
	) : (
		<TariffsTableDesktop {...props} tariffsInfoData={tariffsInfoData} />
	);
}
