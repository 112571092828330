import React, { HTMLProps } from 'react';

import clsx from 'clsx';

import cls from './Container.module.scss';

type ContainerProps = HTMLProps<HTMLDivElement>;

export default function Container(props: ContainerProps) {
	return (
		<div {...props} className={clsx(cls.container, props.className)}>
			{props.children}
		</div>
	);
}
