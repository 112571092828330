import { AllHTMLAttributes, useEffect, useRef, useState } from 'react';
import { AddressSuggestions, DaDataAddressBounds, DaDataSuggestion } from 'react-dadata';
import MaskedTextInput from 'react-text-mask';

import clsx from 'clsx';

import { useTranslate } from '@/hooks/use-translate';

import './LabeledInput.scss';
import './dadata.scss';

type LabeledInputProps = AllHTMLAttributes<HTMLInputElement> & {
	ref?: any;
	dadataValue?: DaDataSuggestion<any>;
	label?: string;
	tooltip?: string;
	inputtype?: 'text' | 'date' | 'tel' | 'number' | 'password' | 'location';
	filterFromBound?: DaDataAddressBounds;
	filterToBound?: DaDataAddressBounds;
	defaultQuery?: string;
	name?: string;
	important?: boolean;
	/** Ввод значений с запятой */
	float?: boolean;
	masklength?: number;
	custommask?: (RegExp | string)[];
	/** Добавляет подсветку и указанный текст с ошибкой */
	error?: string;
	onUpdate?: (e: DaDataSuggestion<any>) => void;
	selectOnBlur?: boolean;
};

export default function LabeledInput(props: LabeledInputProps) {
	const {
		ref,
		dadataValue,
		className,
		label,
		important,
		error,
		masklength,
		custommask,
		float,
		inputtype = 'text',
		onChange,
		selectOnBlur,
		filterFromBound,
		filterToBound,
		defaultQuery,
		...rest
	} = props;

	const name = props.name ?? props.label;
	const [isPasswordHidden, setIsPasswordHidden] = useState(true);
	const refTooltip = useRef(null);

	const t = useTranslate({
		ru: 'en',
	});

	const language: any = t('ru');
	const [value, setValue] = useState(props.defaultValue ?? props.value ?? '');

	useEffect(() => {
		if (props.value != null) {
			setValue(props.value);
		}
	}, [props.value]);

	return (
		<label
			className={clsx(
				'input__container',
				error && 'error',
				rest.disabled && '_disabled',
				className,
			)}
			onMouseMove={({ clientX, clientY }) => {
				const positionGap = 25;

				if (refTooltip.current) {
					refTooltip.current.style.top = clientY + positionGap + 'px';
					refTooltip.current.style.left = clientX - positionGap + 'px';
				}
			}}
		>
			{rest.tooltip ? (
				<span
					ref={refTooltip}
					className={clsx('input__tooltip', rest.disabled && '_disabled')}
				>
					{rest.tooltip}
				</span>
			) : (
				''
			)}
			<span>
				{label}
				{important && (
					<span style={{ color: 'red', marginLeft: '.2rem' }} aria-hidden>
						*
					</span>
				)}
			</span>
			{(() => {
				switch (inputtype) {
					case 'location':
						return (
							<>
								<AddressSuggestions
									inputProps={{
										name,
										...rest,
									}}
									selectOnBlur={selectOnBlur}
									token={process.env.DADATA}
									filterLanguage={language}
									filterFromBound={filterFromBound ?? 'city'}
									filterToBound={filterToBound ?? 'settlement'}
									value={dadataValue}
									defaultQuery={defaultQuery}
									renderOption={(suggestion, inputValue) => {
										if (suggestion.data.geo_lat && suggestion.data.geo_lon) {
											const value = suggestion.value.replace(
												inputValue.trim(),
												`<mark class="react-dadata--highlighted" aria-hidden="true">${inputValue}</mark>`,
											);
											return (
												<div
													aria-label="inputValue"
													dangerouslySetInnerHTML={{ __html: value }}
												></div>
											);
										}

										return '';
									}}
									onChange={(e) => {
										if (filterToBound === 'house' && e.data?.postal_code) {
											e.value = e.data.postal_code + ', ' + e.value;
										}

										setValue(e.unrestricted_value);

										if (rest?.onUpdate) {
											rest.onUpdate(e);
										}
									}}
								/>
							</>
						);
					case 'tel':
						return (
							<input
								autoComplete="off"
								{...rest}
								name={name}
								value={value}
								placeholder="+"
								onChange={(e) => {
									const value = e.target.value;
									const regex = /^\+\d*\s?\d*([\s-]?\d*)*$/;

									if (value === '') {
										setValue('+');
										onChange && onChange(e);
										return;
									}

									if (regex.test(value) || value.length === 0) {
										setValue(e.target.value);
										onChange && onChange(e);
									}
								}}
								onFocus={() => {
									if (
										!value &&
										(!props.defaultValue || props.defaultValue == '')
									) {
										setValue('+');
									}
								}}
							/>
						);
					case 'date':
						return (
							<>
								<MaskedTextInput
									autoComplete="off"
									{...rest}
									name={name}
									value={value}
									onChange={(e) => {
										setValue(e.target.value);
										onChange && onChange(e);
									}}
									mask={
										custommask ?? [
											/\d/,
											/\d/,
											'.',
											/\d/,
											/\d/,
											'.',
											/\d/,
											/\d/,
											/\d/,
											/\d/,
										]
									}
								/>
								<svg width="2.4rem" height="2.5rem" fill="none" viewBox="0 0 24 25">
									<path
										fill="#4587E8"
										d="M12 19.908a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm5 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0-4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-5 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7-12h-1v-1a1 1 0 0 0-2 0v1H8v-1a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-14a3 3 0 0 0-3-3Zm1 17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9h16v9Zm0-11H4v-3a1 1 0 0 1 1-1h1v1a1 1 0 1 0 2 0v-1h8v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 1 1 1v3Zm-13 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
										opacity=".3"
									/>
								</svg>
							</>
						);
					case 'number': {
						return (
							<>
								{masklength ? (
									<MaskedTextInput
										className={rest.disabled && 'disabledInput'}
										autoComplete="off"
										{...rest}
										name={name}
										value={value}
										onChange={(e) => {
											const value = e.target.value;
											if (
												/^\d+$/.test(value.replaceAll('_', '')) ||
												value === ''
											) {
												setValue(e.target.value.replaceAll('_', ''));
												onChange && onChange(e);
											}
										}}
										mask={new Array(masklength).fill(new RegExp(/\d/))}
									/>
								) : custommask ? (
									<MaskedTextInput
										autoComplete="off"
										className={rest.disabled && 'disabledInput'}
										{...rest}
										name={name}
										value={value}
										mask={custommask}
										onChange={(e) => {
											const value = e.target.value;
											setValue(value);
											onChange && onChange(e);
										}}
									/>
								) : !float ? (
									<input
										autoComplete="off"
										type="text"
										className={rest.disabled && 'disabledInput'}
										{...rest}
										name={name}
										value={value}
										onChange={(e) => {
											const value = e.target.value;
											if (/^\d+$/.test(value) || value === '') {
												setValue(e.target.value);
												onChange && onChange(e);
											}
										}}
									/>
								) : (
									<input
										autoComplete="off"
										type="text"
										className={rest.disabled && 'disabledInput'}
										{...rest}
										name={name}
										value={value}
										inputMode="numeric"
										onChange={(e) => {
											let value = e.target.value;
											if (value === '' || /^\d+(\.|,)?\d*$/.test(value)) {
												value = value.replace(/^0+/g, '0');
												value = value.replace(/,/g, '.');
												setValue(value);
												e.target.value = value;
												onChange && onChange(e);
											}
										}}
									/>
								)}
							</>
						);
					}
					case 'password':
						return (
							<>
								<input
									{...rest}
									className={rest.disabled && 'disabledInput'}
									type={isPasswordHidden ? 'password' : 'text'}
									name={name}
									value={value}
									onChange={(e) => {
										setValue(e.target.value.replace(/https?:\/\//gi, ''));
										if (onChange) {
											e.target.value.replace(/https?:\/\//gi, '');
											onChange(e);
										}
									}}
								/>

								<button
									className="
									absolute
									right-[1rem]
									top-[50%]
									!w-fit
									!h-fit
									bg-transparent
									border-none
									p-[.5rem]
								"
									type="button"
									onClick={(e) => {
										e.preventDefault();
										setIsPasswordHidden(
											(isPasswordHidden) => !isPasswordHidden,
										);
									}}
								>
									{isPasswordHidden ? <СrossedEyeIcon /> : <EyeIcon />}
								</button>
							</>
						);
					default:
						return (
							<input
								type="text"
								className={rest.disabled && 'disabledInput'}
								{...rest}
								name={name}
								value={value}
								onChange={(e) => {
									setValue(e.target.value.replace(/https?:\/\//gi, ''));
									if (onChange) {
										e.target.value.replace(/https?:\/\//gi, '');
										onChange(e);
									}
								}}
							/>
						);
				}
			})()}

			{error && <span className="error-text">{error}</span>}
		</label>
	);
}

const svgReset = '!static !right-initial !top-initial !transform-none';

function СrossedEyeIcon(props) {
	return (
		<svg
			className={svgReset}
			{...props}
			width="2rem"
			height="1.5rem"
			fill="none"
			viewBox="0 0 20 20"
		>
			<path
				stroke="#9d9d9d"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="m2 2 20 20M6.713 6.723C3.665 8.795 2 12 2 12s3.636 7 10 7c2.05 0 3.817-.727 5.271-1.712M11 5.058A8.595 8.595 0 0 1 12 5c6.364 0 10 7 10 7s-.692 1.332-2 2.834"
			/>
			<path
				stroke="#9d9d9d"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M14 14.236a3 3 0 0 1-4.13-4.348"
			/>
		</svg>
	);
}

function EyeIcon(props) {
	return (
		<svg
			className={svgReset}
			{...props}
			width="2rem"
			height="1.5rem"
			fill="none"
			viewBox="0 0 20 20"
		>
			<path
				stroke="#9d9d9d"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
			/>
			<path
				stroke="#9d9d9d"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M12.001 5C7.524 5 3.733 7.943 2.46 12c1.274 4.057 5.065 7 9.542 7 4.478 0 8.268-2.943 9.542-7-1.274-4.057-5.064-7-9.542-7Z"
			/>
		</svg>
	);
}
