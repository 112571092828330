export const TelegramIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 26 26">
		<path d="m25.45 4.41-3.78 17.82c-.29 1.26-1.03 1.57-2.08.98l-5.76-4.24-2.78 2.67c-.31.31-.56.56-1.16.56l.41-5.86L20.98 6.7c.46-.41-.1-.64-.72-.23l-13.2 8.31L1.38 13c-1.24-.39-1.26-1.24.26-1.83l22.21-8.56c1.03-.38 1.93.23 1.6 1.8z" />
	</svg>
);

export const WhatsAppIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 26 26">
		<path
			fillRule="evenodd"
			d="M24 13.16c0-6.08-4.92-11-11-11s-11 4.92-11 11c0 1.94.51 3.86 1.49 5.54L2 24.16l5.46-1.49c1.68.98 3.59 1.49 5.53 1.49 6.09 0 11.01-4.92 11.01-11zM9.03 8l.22-.01-.22.01c.15-.01.29-.01.44-.01.06 0 .12.01.18.02.17.02.37.13.43.27.33.74.65 1.49.95 2.25.07.17.03.38-.1.59-.07.11-.17.26-.29.41-.12.16-.39.45-.39.45s-.11.13-.07.29c.03.08.07.16.11.23l.06.1c.31.51.69.98 1.12 1.39.04.03.07.07.11.1.1.09.19.19.29.28a6.428 6.428 0 0 0 1.83 1.14c.05.02.1.04.18.07.07.03.14.05.21.07.07.02.15.02.22-.01s.13-.07.18-.13c.8-.96.87-1.03.87-1.02a.524.524 0 0 1 .41-.13c.07 0 .13.02.19.05.59.27 1.54.68 1.54.68l.64.29c.11.05.2.17.21.29v.01c0 .07.01.19-.02.4-.04.28-.12.63-.21.81-.06.13-.14.24-.23.33-.12.13-.21.21-.36.32-.09.07-.14.1-.14.1-.14.09-.28.17-.42.24-.28.15-.6.24-.92.25-.04 0-.07 0-.11.01-.17.01-.34.02-.51.01l-.62-.1c-1.56-.41-3.01-1.18-4.22-2.25-.21-.19-.42-.39-.62-.59l-.1-.1c-.98-.97-1.72-2.02-2.17-3.02-.22-.49-.36-1.02-.36-1.55 0-.67.22-1.32.62-1.85.01-.02.02-.03.04-.05.12-.08.19-.17.3-.28.1-.1.2-.18.32-.25.13-.06.27-.1.41-.11z"
			clipRule="evenodd"
		/>
	</svg>
);

export const FishIcon = ({ className }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		viewBox="0 0 54.7 27.3"
		className={className}
	>
		<path
			fill="#EBF2FF"
			d="m14.6 13.5-1.5-.6c-.2.4-.1.9.1 1.3l1.4-.7zm38.5-.5 1.6.3v-.8l-1.6.5zm-38.5.5 1.3 1c.4-.6.5-1.4 0-2l-1.3 1zM2.7 25.7v-1.6 1.6zm.2-3.8-1.5-.5 1.5.5zM2.4 5.5 3.9 5l-1.5.5zm0-3.9V0v1.6zm13.7 12.5c1-2.6 3.3-5 6.5-7 3.2-1.9 7.1-3.2 11-3.7 3.9-.4 7.8.1 11 1.7 3.1 1.6 5.7 4.3 7 8.4l3.1-1C53 7.5 49.9 4.2 46 2.2 42.1.3 37.6-.3 33.2.2s-8.7 2-12.3 4.1c-3.6 2.2-6.5 5.1-7.8 8.5l3 1.3zm35.4-1.3c-.5 2.4-2.2 4.7-5 6.5-2.8 1.8-6.4 2.9-10.3 3.3-7.8.7-16.1-2.1-20.2-9.7l-2.8 1.5c4.9 9.1 14.7 12.2 23.3 11.4 4.3-.4 8.5-1.7 11.7-3.8 3.2-2.1 5.7-5 6.4-8.6l-3.1-.6zm-36.9.7-1.3-1s0 .1-.1.1c-.1.1-.2.2-.3.4-.3.3-.6.8-1.1 1.4-.9 1.1-2.2 2.6-3.5 4.1-1.3 1.5-2.7 3-3.9 4.1-.6.5-1.1 1-1.5 1.2-.2.1-.3.2-.4.3h.1v3.2c.5 0 .9-.1 1.2-.3.3-.1.6-.3.9-.5.6-.4 1.2-.9 1.9-1.5 1.3-1.2 2.7-2.8 4.1-4.3 1.4-1.5 2.6-3.1 3.6-4.2.5-.6.9-1.1 1.1-1.4.1-.2.2-.3.3-.4l.1-.1c.1-.1.1-.1-1.2-1.1zM2.7 24.1c.1 0 .4 0 .6.2.2.2.4.3.4.5.1.2 0 .3.1 0 0-.5.2-1.3.6-2.3l-3-1.1C1 22.5.7 23.6.6 24.5c0 .4 0 1 .2 1.6.1.3.4.7.8.9.4.2.8.3 1.2.3l-.1-3.2zm1.7-1.7c2-5.7 1.7-11.9-.5-17.5l-3 1.2c2 4.9 2.2 10.3.5 15.2l3 1.1zM3.9 4.9c-.5-1-.7-1.8-.7-2.2 0-.2 0-.1-.1.1-.1.1-.2.2-.4.3-.1.1-.2.1-.3.1V0C1.7 0 .9.3.4 1.1 0 1.7 0 2.4 0 2.8c0 1 .4 2.2.9 3.3l3-1.2zM2.4 3.2s.1 0 .6.3c.4.3 1 .6 1.6 1.2 1.2 1 2.6 2.5 3.9 3.9 1.3 1.5 2.5 2.9 3.4 4 .4.6.8 1 1 1.3.1.2.2.3.3.4l.1.1 1.3-1 1.3-1s0-.1-.1-.1c-.1-.1-.2-.2-.3-.4-.3-.3-.6-.8-1.1-1.4-.9-1.1-2.1-2.6-3.5-4.2C9.6 5 8.1 3.4 6.7 2.2 6 1.6 5.3 1.1 4.7.7 4 .4 3.2 0 2.4 0v3.2z"
		/>
		<path
			fill="none"
			stroke="#EBF2FF"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="3.209"
			d="M41.1 12.4c0-3.2 4.8-3.3 4.8-.1"
		/>
	</svg>
);
